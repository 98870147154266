<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header"
import store from "@/state/store";

var state = store.state;
export default {
    page: {
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {

        PageHeader

    },
    data() {
        return {

            posSubscriptions: [],
            subscriptionPackages: [],
            page: 1,
            limit: 20,
            tot_pages: 0,
            searchHrLeaveTypes: null,
            PosSubscriptionsSearchMode: false,
            searchModel: "",
            state: state,
            pos_hub: [],
            pos_station: [],
            subscription_package_periods: [],
            subscription_packge: [],

        };
    },
    methods: {


        getPosStation() {
            this.http.get(`pos-stations`, null, null, true).then((res) => {
                this.pos_station = res.data

            })
        },
        getSubscriptionPackagePeriods() {
            this.http.get(`subscription-packages`, null, null, true).then((res) => {
                this.subscription_package_periods = res.data

            })
        },

        addPosSubscriptions() {
            var data = {
                title: "popups.add",
                inputs: [


                    // { model: "icon", type: "file", label: "pos_subscriptions.icon" },
                    {
                        model: "subscription_packge_id",
                        type: "select",
                        options: this.subscription_package_periods.map((e) => {
                            return { value: e.id, label: e.name }
                        }),
                        label: "pos_subscriptions.subscription_packge"
                    },

                ],
                buttons: [
                    {
                        text: "popups.add",
                        closer: true,
                        color: "primary",
                        handler: (obj) => {
                            this.http.do("pos-subscriptions", obj).then(() => {
                                this.get(this.page);
                            });
                        },
                    },
                    {
                        text: "popups.cancel",
                        closer: true,
                        color: "danger",
                        handler: () => {
                            console.log("Like Clicked");
                        },
                    },
                ],
            };
            this.popup.modal(data);
        },
        enable(app) {
            this.http.put(`pos-subscriptions`, app.id, { is_active: 1 }).then(() => {
                this.get(this.page)
            })
        },
        disable(app) {
            this.http.put(`pos-subscriptions`, app.id, { is_active: 0 }).then(() => {
                this.get(this.page)
            })
        },
        editPosSubscriptions(app) {
            var data = {
                title: "popups.edit",
                inputs: [


                    // { model: "icon", type: "file", label: "pos_subscriptions.icon" },
                    {
                        model: "subscription_packge_id",
                        type: "select",
                        options: this.subscription_package_periods.map((e) => {
                            return { value: e.id, label: e.name }
                        }),
                        label: "pos_subscriptions.subscription_packge",
                        value: app.subscription_packge_id
                    },

                ],
                buttons: [
                    {
                        text: "popups.edit",
                        closer: true,
                        color: "primary",
                        handler: (obj) => {
                            this.http.put("pos-subscriptions", app.id, obj).then(() => {
                                this.get(this.page);
                            });
                        },
                    },
                    {
                        text: "popups.cancel",
                        closer: true,
                        color: "danger",
                        handler: () => {
                            console.log("Like Clicked");
                        },
                    },
                ],
            };
            this.popup.modal(data);
        },
        search() {
            this.PosSubscriptionsSearchMode = true;
            this.tot_pages = 0;
            this.http
                .post("pos-subscriptions/search", {
                    search: this.searchModel,
                    limit: this.limit,
                    page: this.page,
                    col: "name",
                })
                .then((res) => {
                    this.posSubscriptions = res.data;
                });
        },
        cancelappsearchMode() {
            this.searchModel = "";
            this.PosSubscriptionsSearchMode = false;
            this.get(this.page);
        },


        deletePosSubscriptions(app) {
            var data = {
                title: "popups.delete",
                msg: "popups.delmessage",
            };
            this.popup.confirm(data).then((resp) => {
                if (resp) {
                    this.http.delete("pos-subscriptions", app.id).then(() => {
                        this.get(this.page);
                    });
                }
            });
        },

        get(page) {
            console.log(page);
            this.http
                .post("pos-subscriptions/paginate", {
                    limit: this.limit,
                    page: page,
                    active: true

                })
                .then((res) => {
                    this.page = page;
                    this.tot_pages = Math.ceil(res.tot / this.limit);
                    this.posSubscriptions = res.data;
                    console.log("req data :", this.posSubscriptions)
                });
        },
    },
    created() {
        this.get(1);
    },
    mounted() {
        this.getPosStation(),
            this.getSubscriptionPackagePeriods()



    },
};
</script>

<template>
    <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
        :title="$t('menu.menuitems.pos_subscription_management.text')" />

    <div class="row">
        <div class="col-6">
            <!-- <div class="search-box chat-search-box w-50">
                        <div class="position-relative">
                            <input @keyup="search()" type="text" class="form-control fa-lg text-light"
                                :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')"
                                v-model="searchModel" style="background-color: #2a3042 !important" />
                            <i class="bx bx-search-alt search-icon text-light"></i>
                        </div>
                    </div> -->
        </div>
        ``
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
        <thead>
            <tr class="text-light text-center" style="background-color: #2a3042 !important">
                <th scope="col">{{ $t("pos_subscriptions.id") }}</th>
                <th scope="col">{{ $t("pos_subscriptions.pos_station") }}</th>
                <th scope="col">{{ $t("pos_subscriptions.subscription_packge") }}</th>
                <th scope="col">{{ $t("pos_subscriptions.period") }}</th>
                <th scope="col">{{ $t("pos_subscriptions.type") }}</th>
                <th scope="col">{{ $t("pos_stations.table.subscription_start_date") }}</th>
                <th scope="col">{{ $t("pos_stations.table.subscription_end_date") }}</th>

                <th scope="col">{{ $t("pos_subscriptions.created") }}</th>
                <th scope="col">{{ $t("pos_subscriptions.operations") }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(app, index) in posSubscriptions" :key="index" class="text-center">
                <td>{{ index + 1 }}</td>
                <td>{{ app?.pos_station_name }}</td>
                <td>{{ $i18n.locale == "en" ? app.name_en : app?.name }}</td>
                <td>{{ app?.period }}</td>
                <td>{{ app?.type == "m" && app?.period > 1 ? $t('pos_subscriptions.months') : app?.type == "m" &&
                    app?.period
                    == 1 ? $t('pos_subscriptions.month') : app?.type == "y" && app?.period > 1 ?
                    $t('pos_subscriptions.years') : $t('pos_subscriptions.year') }}</td>
                <td v-if="app && app?.subscription_start_date">{{ new
                    Date(app?.subscription_start_date).toLocaleString('en-GB') }}</td>
                <td v-else>

                    <span class="">

                        <img src="/assets/images/icons/cross.svg" alt="img" />
                    </span>
                </td>
                <td v-if="app && app?.subscription_start_date">{{ new
                    Date(app?.subscription_end_date).toLocaleString('en-GB') }}</td>
                <td v-else>

                    <span class="">

                        <img src="/assets/images/icons/cross.svg" alt="img" />
                    </span>
                </td>
                <td>{{ new Date(app?.created).toLocaleString('en-GB') }}</td>




                <td class="d-flex">
                    <a class="btn btn-primary mx-1" @click="editPosSubscriptions(app)" href="javascript: void(0);"
                        role="button">{{ $t("popups.edit") }}</a>
                    <a class="btn btn-danger" href="javascript: void(0);" role="button"
                        @click="deletePosSubscriptions(app)">{{ $t("popups.delete") }}</a>
                </td>
            </tr>
        </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div>
        <!--   Apps  Pagination     -->
        <ul class="pagination pagination-rounded justify-content-center mb-2">
            <li class="page-item" :class="{ disabled: page == 1 }">
                <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
                    <i class="mdi mdi-chevron-left"></i>
                </a>
            </li>
            <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
                <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
                    p
                }}</a>
            </li>

            <li class="page-item" :class="{ disabled: page == tot_pages }">
                <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
                    <i class="mdi mdi-chevron-right"></i>
                </a>
            </li>
        </ul>
    </div>

    <!--end-->
</template>

<style scoped>
.btn-close {
    position: relative;
    right: 317px;
    top: 6px;
    font-size: 15px;
}
</style>
